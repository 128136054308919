import * as React from "react";
import FirstApp from "../static/images/first_app.svg";
import AppleRating from "../static/images/apple_rating.svg";
import PlayRating from "../static/images/play_rating.svg";
import Star from "../static/images/star.svg";
import HalfStar from "../static/images/half_star.svg";
import HalfStarFill from "../static/images/half_star_fill.svg";
import SEO from "../components/SEO";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "gatsby";
import { faAppleAlt, faArrowRight, faBullseye, faComments, faMapSigns, faTasks, faVial } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Werkwijze: React.FC = () => {
    return (
        <>
            <SEO
                title="Werkwijze - Leading Apps"
                description="Full service internetbureau. Van website of mobiele applicatie laten maken tot SEO, SEA en logo design. Leading Apps is jouw one stop shop voor alles online."
                article={false}
            />

            <Header />

            <div className="star-ratings" title="4.5 Stars" style={{ position: "relative", boxSizing: "border-box", display: "inline-block" }}>
                <HalfStarFill />
            </div>

            <section id="doelgericht">
                <div className="section-spacer-top"></div>

                <div className="row g-0 width-wrapper-mini mpb-sm mh-centered">
                    <div className="col-10 col-md-12">
                        <ul>
                            <li>
                                <h1 className="bold black mt-4 h-centered">
                                    <span>
                                        Wij werken <span className="orange-underline">doelgericht</span> aan de beste oplossing.
                                    </span>
                                </h1>
                                <h5 className="grey mt-4">
                                    De beste kwaliteit en nieuwste technologie kunnen alleen worden toegepast als er zorgvuldig naar je wensen worden gekeken.
                                    Daarom werkt Leading Apps doelgericht aan een oplossing die voor jou werkt.
                                    Goede communicatie, een snelle en efficiënte aanpak en een doelgerichtige opstelling zorgen ervoor dat we
                                    de topkwaliteit software kunnen leveren. <br /> <br />
                                </h5>
                            </li>
                            <Link to="#stappenplan">
                                <a className="h-centered">
                                    <div className="btn btn-primary mt-3 purple-bg">
                                        <span>Bekijk ons stappenplan </span>
                                        <FontAwesomeIcon icon={faArrowRight} className="arrow" />
                                    </div>
                                </a>
                            </Link>
                        </ul>
                    </div>
                </div>
            </section>

            <section id="use-cases">

                <div className="section-spacer-top"></div>

                <div className="row width-wrapper-large g-0 h-centered">
                    <LeftUseCases />
                    <div className="col-md-4 h-centered v-centered">
                        <div className="row g-0 h-centered">
                            <div className="col-12 col-md-10">
                                <ul>
                                    <li>
                                        <Link to="/#wij">
                                            <div className="appsBlockContainer">
                                                <div className="col-12 appsBlockLogo">
                                                    <AppleRating />
                                                </div>
                                                <div className="col-12 appsBlockScore">
                                                    <div className="starContainer">
                                                        <Star />
                                                    </div>
                                                    <div className="starContainer">
                                                        <Star />
                                                    </div>
                                                    <div className="starContainer">
                                                        <Star />
                                                    </div>
                                                    <div className="starContainer">
                                                        <Star />
                                                    </div>
                                                    <div className="halfStarContainer">
                                                        <HalfStar />
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-10 mt-3">
                                <ul>
                                    <li>
                                        <Link to="/#wij">
                                            <div className="appsBlockContainer">
                                                <div className="col-12 appsBlockLogo">
                                                    <PlayRating />
                                                </div>
                                                <div className="col-12 appsBlockScore">
                                                    <div className="starContainer">
                                                        <Star />
                                                    </div>
                                                    <div className="starContainer">
                                                        <Star />
                                                    </div>
                                                    <div className="starContainer">
                                                        <Star />
                                                    </div>
                                                    <div className="starContainer">
                                                        <Star />
                                                    </div>
                                                    <div className="halfStarContainer">
                                                        <HalfStar />
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <RightUseCases />
                </div>
            </section>

            <section id="stappenplan">

                <div className="section-spacer" ></div>

                <div className="row g-0 width-wrapper-mini mpb-sm mh-centered">
                    <div className="col-10 col-md-12">
                        <ul>
                            <li>
                                <h1 className="bold black mt-4 h-centered">
                                    <span>
                                        Wij maken software <span className="orange-underline">makkelijk</span> met deze stappen.</span>
                                </h1>
                                <h5 className="grey mt-4 mb-4 h-centered">
                                    Wij volgen zes simpele stappen bij het maken van je website of mobiele app(s).
                                    Zo werken we gestructureerd, blijven we beide op de hoogte van de voortgang en het verliezen we het doel niet uit het oog.
                                    Onze eenvoudige strategie zorgt ervoor dat wij de perfecte software voor je kunnen maken.
                                </h5>
                            </li>
                        </ul>
                    </div>
                </div>


                <div className="row g-0 width-wrapper-mini v-centered h-centered">
                    <div className="col-10 col-md-8 mh-centered">
                        <ul>
                            <li>
                                <div className="row g-0">
                                    <Feature
                                        icon={faMapSigns}
                                        title="Richting"
                                        explanation="We bepalen samen een richting. Dat doen we aan de had van jouw doelen. Jij hebt een idee, wij de kennis om die te verwezenlijken."
                                    />
                                    <Feature
                                        icon={faBullseye}
                                        title="Doel(en)"
                                        explanation="We brengen vervolgens alle doelen kaart. Een concreet overzicht van de doelen zorgt voor duidelijkheid, zodat wij gericht aan de slag kunnen."
                                    />
                                    <Feature
                                        icon={faComments}
                                        title="Communiceren"
                                        explanation="We communiceren hoe we de doelen behalen, binnen welke tijd we de doelen halen en aan welke voorwaarden ze moeten voldoen."
                                    />
                                    <Feature
                                        icon={faTasks}
                                        title="Voortgang"
                                        explanation="De lijntjes blijven kort: we zorgen dat je inzicht krijgt in de voortgang. Zo kunnen we snel schakelen als er iets verandert."
                                    />
                                    <Feature
                                        icon={faVial}
                                        title="Proeftijd"
                                        explanation="Nadat de (web)applicatie online staat kun je het uitproberen. Is er iets wat je graag anders ziet? Dan passen wij het voor je aan."
                                    />
                                    <Feature
                                        icon={faAppleAlt}
                                        title="Vruchten"
                                        explanation="De (web)applicatie zorgt ervoor dat je data gestructureerd is, je efficiënter werkt en kwaliteit uitstraald. Je plukt de vruchten van de software."
                                    />

                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className="col-10 col-md-4">
                        <ul>
                            <li>
                                <div className="mockup-wrapper">
                                    <FirstApp className="mockup-container" />
                                </div>
                            </li>
                            <li className="h-centered">
                                <Link to="/contact">
                                    <button className="btn btn-primary mt-3 purple-bg" type="button">
                                        <span>
                                            Neem contact op
                                        </span>
                                        <FontAwesomeIcon icon={faArrowRight} className="arrow" />
                                    </button>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section id="concreet">

                <div className="section-spacer" ></div>

                <div className="row g-0 width-wrapper mpb-sm mh-centered">
                    <div className="col-10 col-md-12">
                        <ul>
                            <li>
                                <h1 className="large bold black mt-4 h-centered text-centered">
                                    <span>
                                        Leuk die holistische aanpak, <br />maar nu even <span className="orange-underline">praktisch</span>
                                    </span>
                                </h1>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row g-0 width-wrapper-large mpb-sm usps">
                    <div className="col-12 col-md-2 square-usp">
                        <ul>
                            <li>
                                <h3 className="bold black mt-4">
                                    <span>
                                        Doorlooptijd
                                    </span>
                                </h3>
                                <h5 className="grey">
                                    Doorgaans gaan we in 4-8 weken van kennismaking tot oplevering. Je doorloopt dit proces samen met je ontwikkelaar.
                                </h5>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-2 square-usp">
                        <ul>
                            <li>
                                <h3 className="bold black mt-4">
                                    <span>
                                        Kosten
                                    </span>
                                </h3>
                                <h5 className="grey">
                                    We werken met budgetten vanaf €2500. Onze offertes zijn overzichtelijk opgebroken in afzonderlijke posten met een vast aantal uren. Zo weet je waar je aan toe bent.
                                </h5>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-2 square-usp ">
                        <ul>
                            <li>
                                <h3 className="bold black mt-4">
                                    <span>
                                        Webshops
                                    </span>
                                </h3>
                                <h5 className="grey">
                                    Yes, webshops doen we ook! Of je nu fysieke producten verkoopt, ebooks, maaltijdbox-abonnementen of concertkaartjes.
                                </h5>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-2 square-usp">
                        <ul>
                            <li>
                                <h3 className="bold black mt-4 ">
                                    <span>
                                        Anders nog iets?
                                    </span>
                                </h3>
                                <h5 className="grey">
                                    We ondersteunen je ook met hosting, fotografie, hoog scoren in Google (SEO), copywriting, logo design, animaties en video’s.
                                </h5>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section id="makkelijk">

                <div className="section-spacer"></div>

                <div className="row g-0 purple-bg pb-5 pt-5">
                    <div className="col width-wrapper-mini">
                        <ul>
                            <li>
                                <h1 className="mega bold white mt-5">
                                    Keep it simple, stupid.
                                </h1>
                                <h5 className="white text-opacity-8 mt-3 mb-5">
                                    Met een strategische aanpak maken wij het schrijven van software makkelijk.
                                    Op die manier kunnen we onze volledige aandacht leggen bij het realiseren van je wensen.
                                    De resultaten spreken voor zich: het product straalt kwaliteit uit, is altijd (99.9%) online
                                    en overal ter wereld bereikbaar.
                                    Ook blijft de website of mobiele app up-to-date door een koppeling met onze API's.
                                    Dit gemak zorgt ervoor dat jij efficiënter kunt werken.
                                </h5>
                            </li>
                            <li className="h-centered">
                                <Link to="/contact">
                                    <button className="btn btn-primary mt-3 blue-bg" type="button">
                                        <span>
                                            Ik wil een gesprek </span>
                                        <FontAwesomeIcon icon={faArrowRight} className="arrow" />
                                    </button>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

type FeatureProperty = {
    icon: any,
    title: string,
    explanation: string
}

const LeftUseCases: React.FC = () => {
    return (
        <div className="col">

            <div className="triple-use-case-wrapper ms-4 mobile-hide">
                <div className="triple-use-cases-left">
                    <img
                        src="https://images.unsplash.com/photo-1612211168136-2eb007670ef9?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=320&q=60"
                        alt="Use case"
                        className="triple-use-case"
                    />
                    <img
                        src="https://images.unsplash.com/photo-1552960394-c81add8de6b8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=320&q=60"
                        alt="Use case"
                        className="triple-use-case"
                    />
                    <img
                        src="https://images.unsplash.com/photo-1498758536662-35b82cd15e29?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=320&q=60"
                        alt="Use case"
                        className="triple-use-case"
                    />
                </div>
            </div>
            <div className="triple-use-case-wrapper me-4  mt-3 mobile-hide">
                <div className="triple-use-cases-left">
                    <img
                        src="https://images.unsplash.com/photo-1600275669439-14e40452d20b?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fHdvcmtpbmd8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=320&q=60"
                        alt="Use case"
                        className="triple-use-case"
                    />
                    <img
                        src="https://images.unsplash.com/photo-1601058272524-0611e132f3c9?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=320&q=60"
                        alt="Use case"
                        className="triple-use-case"
                    />
                    <img
                        src="https://images.unsplash.com/photo-1518186233392-c232efbf2373?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=320&q=60"
                        alt="Use case"
                        className="triple-use-case"
                    />
                </div>
            </div>
            <div className="triple-use-case-wrapper ms-4 mt-3 mobile-hide">
                <div className="triple-use-cases-left">
                    <img
                        src="https://images.unsplash.com/photo-1590649880765-91b1956b8276?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=320&q=60"
                        alt="Use case"
                        className="triple-use-case"
                    />
                    <img
                        src="https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&q=60"
                        alt="Use case"
                        className="triple-use-case"
                    />
                    <img
                        src="https://images.unsplash.com/photo-1571826867433-29518cc0b091?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=320&q=60"
                        alt="Use case"
                        className="triple-use-case"
                    />
                </div>
            </div>
        </div>
    )
}

const RightUseCases: React.FC = () => {
    return (
        <div className="col">
            <div className="triple-use-case-wrapper me-4 mobile-hide">
                <div className="triple-use-cases-right">
                    <img
                        src="https://images.unsplash.com/photo-1610702875413-f4eed90dd2fd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=320&q=60"
                        alt="Use case"
                        className="triple-use-case"
                    />
                    <img
                        src="https://images.unsplash.com/photo-1511250503134-89501c53a815?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&q=60"
                        alt="Use case"
                        className="triple-use-case"
                    />
                    <img
                        src="https://images.unsplash.com/photo-1555421689-3f034debb7a6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=320&q=60"
                        alt="Use case"
                        className="triple-use-case"
                    />
                </div>
            </div>
            <div className="triple-use-case-wrapper ms-4 mt-3 mobile-hide">
                <div className="triple-use-cases-right">
                    <img
                        src="https://images.unsplash.com/photo-1592329347431-0943ca753471?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=320&q=60"
                        alt="Use case"
                        className="triple-use-case"
                    />
                    <img
                        src="https://images.unsplash.com/photo-1584213447128-7d708614348c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=320&q=60"
                        alt="Use case"
                        className="triple-use-case"
                    />
                    <img
                        src="https://images.unsplash.com/photo-1581464668603-1b215d0c5469?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=320&q=60"
                        alt="Use case"
                        className="triple-use-case"
                    />
                </div>
            </div>
            <div className="triple-use-case-wrapper mt-3 me-4 mobile-hide">
                <div className="triple-use-cases-right">
                    <img
                        src="https://images.unsplash.com/photo-1530892691885-a2e83d1e6193?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=320&q=60"
                        alt="Use case"
                        className="triple-use-case"
                    />
                    <img
                        src="https://images.unsplash.com/photo-1613324996029-f6190a17838f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=320&q=60"
                        alt="Use case"
                        className="triple-use-case"
                    />
                    <img
                        src="https://images.unsplash.com/photo-1507207908229-c59ddb730e40?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=320&q=60"
                        alt="Use case"
                        className="triple-use-case"
                    />
                </div>
            </div>
        </div>
    )
}


const Feature: React.FC<FeatureProperty> = (feature) => {

    return (
        <div className="col-md-6 mpb-sm">
            <div className="featureBar">
                <div className="row g-0 feature-hover">
                    <div className="col">
                        <div className="featureIconWrap">
                            <FontAwesomeIcon icon={feature.icon} className="featureIcon" />
                        </div>
                    </div>
                    <div className="row g-0 featureTitlePadding">
                        <div className="col me-1 featureTitleWrap">
                            <h2 className="bold black"><span className="purple-awesome-hover">{feature.title}</span></h2>
                        </div>
                    </div>
                    <div className="row g-0">
                        <div className="col me-3">
                            <h5 className="grey">{feature.explanation}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Werkwijze;